export var PostHogEvents;
(function (PostHogEvents) {
    PostHogEvents["EMAIL_ENRICHMENT_REQUESTED"] = "Email Enrichment Requested";
    PostHogEvents["PHONE_ENRICHMENT_REQUESTED"] = "Phone Enrichment Requested";
    PostHogEvents["AI_SCRIPT_GENERATED"] = "AI Script Generated";
    PostHogEvents["CSV_EXPORT"] = "CSV Export";
    PostHogEvents["CREDIT_USAGE_WARNING"] = "70% Credit Usage Warning";
    PostHogEvents["CREDIT_USAGE_ERROR"] = "100% Credit Usage Error";
    PostHogEvents["USER_PROFILE_UPDATED"] = "User Profile Updated";
    PostHogEvents["USER_CREATED"] = "User Created";
    PostHogEvents["BOUGHT_SUBSCRIPTION"] = "Subscription Bought";
    PostHogEvents["PEOPLE_LIST_CREATED"] = "People List Created";
    PostHogEvents["PEOPLE_SAMPLE_GENERATED"] = "People Sample Generated";
    PostHogEvents["COMPANY_LIST_CREATED"] = "Company List Created";
    PostHogEvents["COMPANY_LIST_CREATED_CSV"] = "Company List Created CSV";
    PostHogEvents["COMPANY_SAMPLE_GENERATED"] = "Company Sample Generated";
    PostHogEvents["JOBS_SAMPLE_GENERATED"] = "Jobs Sample Generated";
    PostHogEvents["JOB_AUTOMATION_STARTED"] = "Job Automation Started";
    PostHogEvents["CORE_SIGNAL_JOB_LIST_CREATED"] = "Core Signal Job List Created";
    PostHogEvents["ATS_JOB_LIST_CREATED"] = "ATS Job List Created";
    PostHogEvents["CRM_SYNC_COMPLETE"] = "CRM Sync Complete";
    PostHogEvents["WATCHED_HOW_TO_USE_TRIGIFY"] = "Watched How to Use Trigify";
    PostHogEvents["BOOKED_ONBOARDING_CALL"] = "Booked Onboarding Call";
    PostHogEvents["ACCESSED_HELP_CENTER"] = "Accessed Help Center";
    PostHogEvents["USER_BOOKED_APPT"] = "User Booked Appointment";
    PostHogEvents["USER_ONBOARDED"] = "User Onboarded";
    PostHogEvents["CREATED_PROFILE_AND_COMPANY"] = "Created Profile and Company";
    PostHogEvents["LINKEDIN_SYNC"] = "LinkedIn Sync Created";
    PostHogEvents["RESULTS_EXPORTED"] = "Results Exported";
    PostHogEvents["UPGRADED_PLAN"] = "User Requested Upgrade";
})(PostHogEvents || (PostHogEvents = {}));
export class PosthogService {
    client;
    PROJECT_ID = 54077;
    constructor(client) {
        this.client = client;
        this.client = client;
    }
    static sendUserCreatedEvent(jsClient, userId, payload) {
        jsClient.capture(PostHogEvents.USER_CREATED, {
            distinctId: userId,
            email: payload.email,
            isFreeTrial: payload.freeTrial,
            subscription: payload.subscription_plan,
            name: `${payload.firstName} ${payload.lastName}`,
        });
    }
    track(event, userId, properties) {
        this.client.capture({
            distinctId: userId,
            event,
            properties,
        });
    }
    async getUserEvents(events, userId) {
        const eventsString = events.map((event) => `'${event}'`).join(",");
        const payload = {
            query: {
                kind: "HogQLQuery",
                query: `SELECT event from events where distinct_id = '${userId}' and event IN (${eventsString})`,
            },
        };
        // params.append("event", events.join(","));
        const data = await this.client.fetch(`https://app.posthog.com/api/projects/${this.PROJECT_ID}/query`, {
            headers: {
                Authorization: `Bearer ${process.env.POSTHOG_API_KEY}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
            method: "POST",
        });
        const json = (await data.json());
        console.log("JSON", json);
        return json.results;
    }
}
export { posthog } from "../dependencies/posthog.js";
